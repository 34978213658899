import React from 'react'

const MainText = () => {
    return (
        // <div className='maintexts'>
        //     <h4>السـلام عليكـم -</h4>
        //     <h1>أحمد سمير الغامدي</h1>
        //     <p>مطور برمجيات ومصمم واجهات UI/UX.</p>
        //     <p>انشأت هذا الموقع بإستخدام React/SASS,</p>
        //     <p>والموقع متجاوب بشكل تام مع جميع احجام الشاشات.</p>
        // </div>
        <div className='maintexts'>
            <h4>السلام عليكم</h4>
            <h1>AHMED SAMEER ALGHAMDI</h1>
            <p>specialize in Software Engineering (backend) and Cybersecurity with a diverse set of skills and looking to make a positive impact in the industry</p>
            <br />
            <p>I built this website using React & Sass</p>
            <p>The website is responsive with all screen sizes</p>
        </div>
    )
}

export default MainText
