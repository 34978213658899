import React from 'react'
import Button from '../components/Button'
import { IoMdDownload } from 'react-icons/io'
import { GrTest } from "react-icons/gr";
import { GrTestDesktop } from "react-icons/gr";
import { MdCleaningServices } from "react-icons/md";
import { PiTreeStructure } from "react-icons/pi";
import { MdOutlineSecurity } from "react-icons/md";
import { IoCloud } from "react-icons/io5";
import cv from '../assets/Ahmed-Alghamdi-CV.pdf'
const ResumePage = () => {



    return (
        <div className="resume" dir="ltr">
            <div className='resume-title'>
                <h1 className='resume-title__shadow'>RESUME</h1>
                <h1 className='resume-title__title'>RESUME</h1>
            </div>
            <div className='infotech'>
                <div className="information_container">

                    {/* <p>أنا أحمد سمير الغامدي, مطور برمجيات ومصمم. تخرجت من معهد الإدارة العامة بتخصص برمجة الحاسب الآلي بمعدل 4.99\5. بعد تخرجي سجلت كمتدرب في البرمجة في شركة كندية لمدة شهر وأتطلع الأن لوظيفه لكسب خبرة في سوق العمل ولتطوير مهاراتي</p> */}
                    {/* <p>a dedicated Software Developer specializing in full-stack development with a keen eye for UI/UX design, I hold an Associate Degree in
                        Computer Programming from IPA and am nearing the completion of my Bachelor's degree in Computer Science. <br /><br />My experience includes
                        front-end and back-end development, primarily using React for client-side programming and ASP.NET Web API for server-side solutions. I
                        am actively seeking job opportunities or co-op training positions where I can further enhance my skills, contribute positively to the industry,
                        and expand my practical knowledge.</p> */}
                    <p>Software Engineer specializing in backend development and cybersecurity. With hands-on experience in building secure, scalable APIs and applications, combined with certifications like CompTIA Security+ and AZ-900, I'm enthusiastic about contributing to and growing with innovative teams. </p>

                    {/* <button className="slide"><IoMdDownload /> CVتنزيل ال</button> */}
                    <a href={cv} rel="noreferrer" style={{ textDecoration: 'none' }} target='_blank'><Button value="Download/View CV" icon={<IoMdDownload size='28' style={{}} />} ></Button></a>
                    {/* <a href={cv} rel="noreferrer" style={{ textDecoration: 'none' }} target='_blank'><Button value="CVعرض/تحميل الـ" icon={<IoMdDownload size='28' style={{}} />} ></Button></a> */}
                </div>
                <div className="technologies_container" >
                    <h2>FAMILIAR TECHNOLOGIES</h2><br />
                    <div className='technologies_container__list-container'>
                        <ul>
                            <li className='technologies_container__list-container__list-header'>Languages</li>
                            <li><i className='devicon-html5-plain colored'></i> HTML</li>
                            <li><i className='devicon-css3-plain colored'></i> CSS</li>
                            <li><i className='devicon-css3-plain colored'></i> SCSS</li>
                            <li><i className='devicon-javascript-plain colored'></i> JavaScript</li>
                            <li><i className='devicon-typescript-plain colored'></i> TypeScript</li>
                            <li><i className='devicon-csharp-plain colored'></i> C#</li>
                            <li><i className='devicon-php-plain colored'></i> PHP</li>
                            <li><i className='devicon-sass-plain colored'></i> Sass</li>
                            <li><i className='devicon-postgresql-plain colored'></i> SQL</li>
                        </ul>
                        <ul>
                            <li className='technologies_container__list-container__list-header'>Frameworks</li>
                            <li><i className='devicon-react-plain colored'></i> React</li>
                            <li><i className='devicon-laravel-plain colored'></i> Laravel</li>
                            <li><i className='devicon-dotnetcore-plain colored'></i> ASP.NET Core</li>
                        </ul>
                        <ul>
                            <li className='technologies_container__list-container__list-header'>Development Tools</li>
                            <li><i className='devicon-gitlab-plain colored'></i> GitLab</li>
                            <li><i className='devicon-github-plain'></i> GitHub</li>
                            <li><i className='devicon-git-plain colored'></i> Git</li>
                            <li><i className='devicon-docker-plain colored'></i> Docker</li>
                            <li><i className='devicon-vscode-plain colored'></i> Visual Studio Code</li>
                            <li><i className='devicon-visualstudio-plain colored'></i> Visual Studio</li>
                            <li><i className='devicon-postgresql-plain colored'></i> Postgresql</li>
                            <li><i className='devicon-figma-plain colored'></i> Figma</li>
                        </ul>
                        <ul>
                            <li className='technologies_container__list-container__list-header'>Best Practices</li>
                            <li><GrTest /> Unit Testing</li>
                            <li><GrTestDesktop /> Intergration Testing</li>
                            <li><PiTreeStructure /> SOILD Principles</li>
                            <li><MdCleaningServices /> Clean Code</li>
                            <br />
                            <li className='technologies_container__list-container__list-header'>Certifications</li>
                            <li><MdOutlineSecurity  /> CompTIA Security+</li>
                            <li><IoCloud  /> Azure AZ-900</li>
                        </ul>

                    </div>
                </div>

            </div>

            <div className='resume-container'>
                <div className='resume-container__meta'>
                    <h2>Backend Developer</h2>
                    <h3>Tamra Capital</h3>
                </div>
                <div className='resume-container__date'><p>Feb 2025 - Present</p></div>
                <div className='resume-container__main'>
                    <p>
                        <ul>
                            <li>Developing and maintaining highly Secure financial backend services</li>
                            <li>Implementing secure and scalable APIs for financial transactions and user management</li>
                            <li>Collaborating with frontend and QA teams to ensure seamless application functionality</li>
                        </ul>
                    </p>
                </div>
                
            </div>
            
            <div className='resume-container'>
                <div className='resume-container__meta'>
                    <h2>Full-Stack Developer</h2>
                    <h3> Derwaza IT</h3>
                </div>
                <div className='resume-container__date'><p>Aug 2024 - Present</p></div>
                <div className='resume-container__main'>
                    <p>
                        <ul>
                            <li>Working on the front-end development: using React, JavaScript, HTML, and CSS,
                                enhancing site responsiveness and user experience</li>
                            <li>Working on the back-end: using Asp.Net and Laravel</li>
                            <li>Developing and maintaining: the company's software</li>
                            <li>Enhanced communication skills: by working closely with a team, which helped in efficient
                                workload distribution and support amongst team members.</li>
                        </ul>
                    </p>
                </div>
                <div className='resume-container__tech-used'>
                    <p>Technologies used:</p>
                    <div className='resume-container__tech-used__list'>
                        <div>Asp.net</div><div>JavaScript</div><div>React</div><div>Laravel</div><div>HTML/CSS</div><div>SQL Server</div>
                    </div>
                </div>
            </div>


            <div className='resume-container'>
                <div className='resume-container__meta'>
                    <h2>Core Team Member | Software Developer</h2>
                    <h3>Google Developer Student Club at AOU</h3>
                </div>
                <div className='resume-container__date'><p>Oct 2023 - Dec 2024</p></div>
                <div className='resume-container__main'>
                    <p>
                        I was a core team member at GDSC at AOU, I participated in hackathon projects with a
                        team as a software developer and tried to bring innovative ideas to life, and written some
                        technical content.
                    </p><br />
                    <h3>Projects:</h3>
                    <p>
                        - Absherthon | worked on a project that simplifies investing for foreign investors
                        <br />
                        - Student Clubs Hackathon | developed an idea of conducting a smart public transportation
                        hackathon at AOU
                        <br />
                        - Google AI Hackathon | developed a complaint tracker system that uses AI to detect invalid
                        complaints based on the provided terms of service (we used the Google Gemini API)
                    </p>
                    {/* <p>
                        عملت مع فريق مكون من 4 في تطوير متجر الكتروني في شركة كندية. دوري كان في بناء واجهة المستخدم (Front-end) واستعملت React/JavaScript/HTML/CS.
                        وساعدت في الباك اند.
                    </p><br />
                    <h4>ماذا تعلمت: </h4>
                    <p>
                        مشاركة العمل مع فريق مكون 4 ساعدني في تطوير مهارات التواصل, وفهم طريقة تقسيم المهام على أعضاء الفريق ومساعدة بعض لإنهاء المشروع بكفائه وسرعه قبل موعد التسليم.
                        وتطورت مهاراتي في استعمال التقنيات المذكورة في الاسفل
                    </p> */}


                </div>
                <div className='resume-container__tech-used'>
                    <p>Technologies used:</p>
                    <div className='resume-container__tech-used__list'>
                        <div>React</div><div>Asp.net</div><div>SQLite</div><div>JavaScript</div><div>HTML/CSS</div><div>Figma</div>
                    </div>
                </div>
            </div>

            {/******************** Second job experience *********************/}
            <div className='resume-container'>
                <div className='resume-container__meta'>
                    <h2>Software Developer (Intern)</h2>
                    <h3>QuiteClicks</h3>
                </div>
                <div className='resume-container__date'><p>2021 Sep-Oct</p></div>
                <div className='resume-container__main'>
                    <p>
                        During my internship, I collaborated with a team of 4 to develop "Kidzie, an online children
                        clothing store. My primary role involved working on the front end using technologies such
                        as React, JavaScript, HTML, and CSS. I also contributed to the back end to a lesser extent.
                    </p><br />
                    <h3>Achievements:</h3>
                    <p>
                        - Enhanced communication skills by working closely with a team, which helped in
                        efficient workload distribution and support amongst team members
                        <br />
                        - Improved technical proficiency and practical knowledge of front-end and back-end
                        development.
                        <br />
                        - Successfully met project deadlines through effective collaboration and task
                        management.
                    </p>
                    {/* <p>
                        عملت مع فريق مكون من 4 في تطوير متجر الكتروني في شركة كندية. دوري كان في بناء واجهة المستخدم (Front-end) واستعملت React/JavaScript/HTML/CS.
                        وساعدت في الباك اند.
                    </p><br />
                    <h4>ماذا تعلمت: </h4>
                    <p>
                        مشاركة العمل مع فريق مكون 4 ساعدني في تطوير مهارات التواصل, وفهم طريقة تقسيم المهام على أعضاء الفريق ومساعدة بعض لإنهاء المشروع بكفائه وسرعه قبل موعد التسليم.
                        وتطورت مهاراتي في استعمال التقنيات المذكورة في الاسفل
                    </p> */}


                </div>
                <div className='resume-container__tech-used'>
                    <p>Technologies used:</p>
                    <div className='resume-container__tech-used__list'>
                        <div>Node.js</div><div>JavaScript</div><div>React</div><div>Express.js</div><div>HTML/CSS</div><div>Postgresql</div>
                    </div>
                </div>
            </div>
            {/******************** Third job experience *********************/}
            {/* <div className='resume-container'>
                <div className='resume-container__meta'>
                    <h2>دبلوم برمجة حاسب الي</h2>
                    <h3>معهد الإدارة العامة</h3>
                </div>
                <div className='resume-container__date'><p>2019 - 2021</p></div>
                <div className='resume-container__main'>
                    <p>
                        4.99 GPA في برمجة الحاسب الالي.
                        4.5 GPA في اللغة الإنجليزية.
                    </p><br />
                    <p>
                        مشروع تخرجي كان عن موقع يجمع المطاعم والعملاء لحجز الطاولات بحسب الحجم المراد. ويدير الحجوزات بكل تلقائي بدون أي تعارض او حاجة لتدخل أي موظف
                    </p>


                </div>

            </div> */}
            {/******************** third job experience *********************/}
            <div className='resume-container'>
                <div className='resume-container__meta'>
                    <h2>Jewellery Store Manager/ IT Manager </h2>
                    <h3>AlBakran Jewelry</h3>
                </div>
                <div className='resume-container__date'><p>2018 - 2019</p></div>
                <div className='resume-container__main'>
                    <p>
                        While working at a high-end watches and jewellery retail brick-and-mortar store, I was the
                        person responsible for setting up the store's electronics(invoice printer, barcode printer,
                        barcode scanner, accounting software,..etc).

                        working there also increased my communication and bargaining skills with customers.
                    </p>


                </div>

            </div>
            {/* <div className='resume-container'>
                <div className='resume-container__meta'>
                    <h2>إدارة متجر مجوهرات</h2>
                    <h3>البكران للساعات السويسرية</h3>
                </div>
                <div className='resume-container__date'><p>2018 - 2019</p></div>
                <div className='resume-container__main'>
                    <p>
                        عملت في شراء وتركيب أجهزة طباعة الفواتير والملصقات الخاصة بالمتجر وتصميمها وتركيب برنامج الحسابات.
                        إدارة حسابات المحل وإدخال البيانات وإدارة المحل بشكل عام.
                    </p>


                </div>

            </div> */}

        </div>

    )
}

export default ResumePage
